import styled from 'styled-components'

const MeunFooterItem = styled.a`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 19px;
  text-align: right;
  text-decoration: none;
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 1px solid transparent;
  transition: border-color .55s ease-out;
  padding-bottom: 1px;
  width: max-content;
  :hover {
    color: ${({theme}) => theme.colors.dark};
    border-bottom: 1px solid ${({theme}) => theme.colors.dark};
    transition: border-color .35s ease-in;
    text-decoration: none;
  }
  &:last-child {
     margin-top: 10px;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    text-align: right;
    text-transform: none;
  }
`;

export default MeunFooterItem;