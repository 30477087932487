import React from "react"
import { Link as GatsbyLink } from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll';
// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const UniversalLink = ({ children, to, activeClassName, partiallyActive, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.


  function handleClick(e) {
    e.preventDefault();
    scrollTo(`${to}`)
  }

  const internal = /^\/(?!\/)/.test(to)
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }
  else if(to.charAt(0) === "#") {
    return (
      <a href={to} {...other} onClick={handleClick} rel="noopener noreferrer">
        {children}
      </a>
    )
  }
  return (
    <a href={to} {...other} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}
export default UniversalLink
