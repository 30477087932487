import React from "react"
import {StaticQuery, graphql} from "gatsby"
import styled from 'styled-components';
import MenuItem from "./MenuItem"

const HamburgerMenu = styled.div`
  position: relative;
  width: 20px;
  span {
    background-color ${({theme}) => theme.colors.dark};
    margin: 8px 0px;
    width: 30px;
    height: 1px;
    display: block;
   }
`;

const MenuMobile = styled.div`
  padding-top: 90px;
  position: fixed;
  height: 100vh;
  width: 190px;
  right: -195px;
  top: 0px;
  background-color: ${({theme}) => theme.colors.primary};
  z-index: 9999;
  transition: all 0.4s;
  
  &.active {
    right: 0px;
  }
  
`;

const ImageArrow = styled.div`
  position: absolute;
  top: 30px;
  right: 20px;
`;

/**
 * Define MenuItem fragment and get all primary menu items.
 */
const MENU_QUERY = graphql`

    fragment MenuItem on WPGraphQL_MenuItem {
        id
        label
        url
        title
        target
    }

    query GETMAINMENU {
        wpgraphql {
            menuItems(where: {location: MENU_1}) {
                nodes {
                    ...MenuItem
                }
            }
            generalSettings {
                url
            }
        }
    }
`

const Menu = () => {

  function showMenu() {
    document.getElementById("menuMobile").classList.add("active");
  }
  function hiddenMenu() {
    document.getElementById("menuMobile").classList.remove("active");
  }

  return (
    <StaticQuery
      query={MENU_QUERY}
      render={(data) => {
        if (data.wpgraphql.menuItems) {
          const menuItems = data.wpgraphql.menuItems.nodes
          const wordPressUrl = data.wpgraphql.generalSettings.url

          return (
            <>
            <div className="pt-1 d-none d-md-block">
              {
                menuItems &&
                menuItems.map((menuItem) => (
                  <MenuItem key={menuItem.id} menuItem={menuItem} wordPressUrl={wordPressUrl}/>
                ))
              }
            </div>
            <HamburgerMenu className="pt-1 d-block d-md-none" onClick={showMenu}>
              <span></span>
              <span></span>
              <span></span>
            </HamburgerMenu>
            <MenuMobile id="menuMobile" className="d-flex d-md-none flex-column">
              <ImageArrow onClick={hiddenMenu}>
                <svg className="arrow" width="33px" height="11px" viewBox="0 0 33 11" version="1.1"
                     xmlns="http://www.w3.org/2000/svg">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g className="arrow" id="arrowRight" transform="translate(-1349.000000, -2531.000000)"
                       fill="#000000" fillRule="nonzero">
                      <path id="Line-Copy-2"
                            d="M1372.35242,2531.2557 L1372.78617,2531.50443 L1380.74871,2536.07039 L1381.51981,2536.51256 L1380.7415,2536.94193 L1372.70459,2541.37566 L1372.26679,2541.61718 L1371.78375,2540.74159 L1372.22155,2540.50007 L1378.587,2536.988 L1372.50402,2536.93807 L1349.74587,2536.74998 L1349.24588,2536.74585 L1349.25415,2535.74588 L1349.75413,2535.75002 L1372.51228,2535.9381 L1378.595,2535.988 L1372.28872,2532.37192 L1371.85497,2532.1232 L1372.35242,2531.2557 Z"></path>
                    </g>
                  </g>
                </svg>
              </ImageArrow>
              {
                menuItems &&
                menuItems.map((menuItem) => (
                  <MenuItem key={menuItem.id} menuItem={menuItem} wordPressUrl={wordPressUrl}/>
                ))
              }
            </MenuMobile>
            </>
          )
        }
        return null
      }}
    />
  )
}

export default Menu
