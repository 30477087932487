import React, { Component } from "react";
import styled from 'styled-components';

const Fade = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: ${({theme}) => theme.colors.white}; 
  opacity: 1;
  display: block;
  transition: opacity 3s;
  z-index: 999999;
  
  &.loaded {
    opacity: 0;
  }
  
`;

export default class Fader extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.addEventListener("load", this.handleLoad);
    this.handleLoad();
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.handleLoad);
  }

  handleLoad() {
    var fader = document.getElementById("fader");
    var fadeEffect = setInterval(function () {
      if (!fader.style.opacity) {
        fader.style.opacity = 1;
      }
      if (fader.style.opacity > 0) {
        fader.style.opacity -= 0.2;
      } else {
        clearInterval(fadeEffect);
      }
    }, 100);
    setTimeout(function(){fader.style.display = 'none'},3000);
  }

  render() {
    return (
      <Fade id="fader"/>
    );
  }
}