import {createGlobalStyle} from 'styled-components';

import DomaineWoff from './Domaine/domaine-text-web-light.woff';
import DomaineWoff2 from './Domaine/domaine-text-web-light.woff2';

import PublicSansItalic from './PublicSans/PublicSans-Italic-VariableFont_wght.ttf';
import PublicSans from './PublicSans/PublicSans-VariableFont_wght.ttf';

export default createGlobalStyle`
    @font-face {
        font-family: 'Domaine';
        src: local('Domaine'), local('Domaine'),
        url(${DomaineWoff2}) format('woff2'),
        url(${DomaineWoff}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Public Sans';
        src: url(${PublicSans}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'PublicSansItalic';
        src: local('PublicSansItalic'), local('PublicSansItalic'),
        url(${PublicSansItalic}),
        font-weight: normal;
        font-style: normal;
       
    }
`;