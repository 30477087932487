import styled from 'styled-components'

const H4 = styled.h4`
  font-weight: normal;
  font-size: 36px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 46px;
  text-align: ${({textCenter}) => textCenter ? 'center' : 'left'};
  
  a {
    transition: all 0.3s;
    color: ${({theme}) => theme.colors.primary};
    :hover {
      color: ${({theme}) => theme.colors.dark};
      text-decoration: none;
    }
  }
   ${({theme}) => theme.media.mobile} {
    font-size: 32px;
    letter-spacing: 0.89px;
    line-height: 42px;
    text-align: center;
  }
}
  
`;

export default H4;