import React from "react"
import { CreateLocalLink } from "../utils"
import UniversalLink from "./UniversalLink"
import styled from 'styled-components'

const Element = styled.a`
   margin-left: 35px;
   font-size: 16px;
   font-family: ${({theme}) => theme.fontFamily.publicSans};
   letter-spacing: 2px;
   line-height: 19px;
   text-transform: uppercase;
   color: ${({theme}) => theme.colors.dark};
   font-variation-settings: 'wdth' 700, 'wght' 400;
   transition: all 0.3s;
   outline-width: 0;
   border-bottom: 1px solid transparent;
   transition: border-color .55s ease-out;
   padding-bottom: 1px;
   width: max-content;
    :hover {
      color: ${({theme}) => theme.colors.dark};
      border-bottom: 1px solid ${({theme}) => theme.colors.dark};
      transition: border-color .35s ease-in;
      text-decoration: none;
    }
   
   
   ${({theme}) => theme.media.mobile} {
      padding: 1px 0px;
      margin-bottom: 15px;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 16px;
      margin-left: 30px;
      margin-right: 10px;
   }
   
`;

const MenuItem = ({ menuItem, wordPressUrl }) => {
  return (
    <Element as={UniversalLink} to={CreateLocalLink(menuItem, wordPressUrl)}>
      {menuItem.label}
    </Element>
  )
}

export default MenuItem
