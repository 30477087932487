import React from "react"
import styled from 'styled-components';
import FluidImage from "../../components/FluidImage"

const LinkImage = styled.a`
  overflow: hidden;
  ${({theme}) => theme.media.mobile} {
     height: auto;
     
  }
`;
const LinkImageImg = styled.span`
   height: 254px;
   width: 100%;
   object-fit: cover;
   max-width: 100%;
  ${({theme}) => theme.media.mobile} {
     height: 50vw;
  }
   
`;
const ContainerImage = styled.div`
   width: 100%;
`;
const ImageFooter = ({ item }) => {

  const { image, link } = item;
  return (
    <LinkImage as="a" href={link} target="_blank"
               data-sal="fade"
               data-sal-delay="300"
               data-sal-duration="1000"
               data-sal-easing="slide-up"
               className="mx-0 mx-md-2 position-relative d-block">
      <ContainerImage>
        <LinkImageImg as={FluidImage} image={image} className="img-fluid" />
      </ContainerImage>
    </LinkImage>
)
}

export default ImageFooter
