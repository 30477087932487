import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from 'styled-components';
import FluidImage from "./FluidImage"
/**
 * Define MenuItem fragment and get all primary menu items.
 */
const LOGO_QUERY = graphql`
  
    query GETLOGO {
        wpgraphql {
            myOptionsPage {
                generalOptions {
                    headerLogo {
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxHeight: 100, quality: 100, cropFocus: CENTER) {
                                    ...GatsbyImageSharpFluid_tracedSVG
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
const StyledWrapper = styled.div`
   width: 166px;
`;
const HeaderLogo = () => {
  return (
    <StaticQuery
      query={LOGO_QUERY}
      render={(data) => {
        if (data.wpgraphql.myOptionsPage.generalOptions.headerLogo) {
          const logo = data.wpgraphql.myOptionsPage.generalOptions.headerLogo;
          return (
            <StyledWrapper>
              <img src={logo.sourceUrl} alt={logo.altText} />
            </StyledWrapper>
          )
        }
        return null
      }}
    />
  )
}

export default HeaderLogo
