const FluidImageFragment = `
    fragment GatsbyImageSharpFluid_tracedSVG on ImageSharpFluid {
        tracedSVG
        aspectRatio
        src
        srcSet
        sizes
    }
`
const gatsbyImageSharpFixed = `
    fragment GatsbyImageSharpFixed on ImageSharpFixed {
        base64
        width
        height
        src
        srcSet
    }
`

module.exports.FluidImageFragment = FluidImageFragment
module.exports.gatsbyImageSharpFixed = gatsbyImageSharpFixed
