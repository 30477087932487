import React, {Component} from "react";
import {StaticQuery, graphql} from "gatsby"
import ImageFooter from "./ImageFooter/ImageFooter"
import MenuFooterItem from "./MenuFooterItem/MenuFooterItem"
import H4 from "./H4/H4"
import styled from 'styled-components'
import {Link} from 'gatsby';
import Slider from "react-slick";

const {FluidImageFragment} = require("../templates/fragments")
const FooterContainer = styled.footer`
  background-color: ${({backgroundColor}) => backgroundColor};
  margin-top: 100px;
   ${({theme}) => theme.media.mobile} {
     margin-top: 70px;
  }
`;

const TextFooter = styled.p`
  max-width: ${({rightText}) => rightText ? "none" : "660px"};
  font-size: ${({isSmall}) => isSmall ? "14px" : "16px"};
  font-weight: 200;
  letter-spacing: ${({isSmall}) => isSmall ? "0.33px" : "0.84px"};
  line-height: ${({isSmall}) => isSmall ? "20px" : "26px"};
  text-align: ${({rightText}) => rightText ? "right" : "left"};
  a {
    ${({isSmall}) => isSmall ? "text-decoration: none; font-weight: 300; " : ""};
    ${({isSmall}) => isSmall ? " font-weight: 300; " : ""};
    :hover {
       color: inherit;
    }
   
  }
  ${({theme}) => theme.media.mobile} {
    text-align: left;
    
  }
  
`;
const SocialLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  
`;
const SocialLink = styled.a`
  color: ${({theme}) => theme.colors.dark};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  border-bottom: 1px solid transparent;
  transition: border-color .55s ease-out;
  padding-bottom: 1px;
  width: max-content;
  text-decoration: none;
  position: relative;
  :hover {
    color: ${({theme}) => theme.colors.dark};
    border-bottom: 1px solid ${({theme}) => theme.colors.dark};
    transition: border-color .35s ease-in;
    text-decoration: none;
  }
`;
const MenuFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  
`;
const FooterImageContainer = styled.div`
 transform: translateY(-60px);
 .slick-active {
    &.slick-current {
      ${({theme}) => theme.media.mobile} {
        margin-right: 20px;
      }
    }
  }
`;


/**
 * Define MenuItem fragment and get all primary menu items.
 */
const FOOTER_QUERY = graphql`
    query FooterQuery {
        wpgraphql {
            myOptionsPage {
                generalOptions {
                    fieldGroupName
                    backgroundFooter
                    footerTitle
                    images {
                        image {
                            id
                            altText
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 350, quality: 90, cropFocus: CENTER) {
                                        ...GatsbyImageSharpFluid_tracedSVG
                                    }
                                }
                            }
                        }
                        link
                    }
                    logo {
                        altText
                        sourceUrl
                    }
                    menuFooter {
                        links {
                            title
                            url
                        }
                    }
                    textBottom
                    textLeft
                    socialLinks {
                        text
                        link
                    }
                }
            }
        }
    }
`

export default class PostsList extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const settings = {
      dots: false,
      infinite: false,
      arrows: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            autoplay: true,
            autoplaySpeed: 6000,
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        }
      ]
    };

    return (
      <StaticQuery
        query={FOOTER_QUERY}
        render={(data) => {
          const {backgroundFooter, menuFooter, footerTitle, textBottom, textLeft, images, logo, socialLinks} = data.wpgraphql.myOptionsPage.generalOptions;
          return (
            <>
            <div className="container pt-5 mt-1 mt-md-5">
              <div className="row justify-content-center text-center mb-3">
                <H4 data-sal="fade"
                    data-sal-delay="300"
                    data-sal-duration="1000"
                    data-sal-easing="ease"
                    textCenter className="mb-3 mb-md-2" dangerouslySetInnerHTML={{__html: footerTitle}}></H4>
              </div>
            </div>
            <FooterContainer id="footer" backgroundColor={backgroundFooter} className="container-fluid px-0 px-md-3 px-md-5 pb-4">
              <FooterImageContainer className="container px-0 px-md-2 mb-0">
                <div className="">
                  <Slider ref={c => (this.slider = c)} {...settings}>
                  {images && images.map((item, index) => <ImageFooter key={item.image.id}
                                                                      item={item}/>)}
                  </Slider>
                </div>
              </FooterImageContainer>
              <div className="margin-footer row justify-content-between align-items-end pt-2 pt-md-2 px-4 px-md-0 ">
                <div className="col-12 col-md-6 pb-4 pb-md-0">
                  <img data-sal="slide-up"
                       data-sal-delay="300"
                       data-sal-duration="1000"
                       data-sal-easing="ease"
                       src={logo.sourceUrl} alt={logo.altText} className="imgFooter mb-5"/>
                  <TextFooter data-sal="slide-up"
                              data-sal-delay="300"
                              data-sal-duration="1000"
                              data-sal-easing="ease">{textLeft}</TextFooter>
                  <SocialLinks data-sal="slide-up"
                               data-sal-delay="300"
                               data-sal-duration="1000"
                               data-sal-easing="ease" className="d-flex flex-row pt-md-3 pb-md-2">
                    {socialLinks && socialLinks.map((item, index) => <SocialLink href={item.link} key={item.index} className="mr-3" target="_blank" rel="noopener noreferrer" >{item.text}</SocialLink>)}
                  </SocialLinks>
                  <TextFooter data-sal="slide-up"
                              data-sal-delay="300"
                              data-sal-duration="1000"
                              data-sal-easing="ease"
                              className="mt-5" dangerouslySetInnerHTML={{__html: textBottom}}></TextFooter>
                </div>
                <div className="col-12 col-md-6 text-right">
                  <MenuFooterContainer data-sal="slide-up"
                                       data-sal-delay="300"
                                       data-sal-duration="1000"
                                       data-sal-easing="ease"
                                       className="d-flex align-items-start justify-content-start align-items-md-end justify-content-md-end">
                    {menuFooter && menuFooter.map((item, index) => <MenuFooterItem key={`menufooter${index}`} as={Link}
                                                                                   to={item.links.url}>{item.links.title}</MenuFooterItem>)}
                  </MenuFooterContainer>
                  <TextFooter data-sal="slide-up"
                              data-sal-delay="300"
                              data-sal-duration="1000"
                              data-sal-easing="ease"
                              rightText isSmall>Copyright © 2020 Mindvue.pl <span className="pl-3">Website <a href="http://weareunwind.com/" target="_blank" rel="noopener noreferrer">Unwind Studio</a></span>
                  </TextFooter>
                </div>
              </div>
            </FooterContainer>
            </>
          )
        }}
      />
    )
  }
}