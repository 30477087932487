/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import GlobalFonts from '../fonts/fonts';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { theme } from '../style/theme';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Fader from '../components/Transitions/Fader';
import "./layout.css";

const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    font-family: ${({theme}) => theme.fontFamily.publicSans};
    background-color: ${({theme}) => theme.colors.white};
    color: ${({theme}) => theme.colors.dark};
    font-variation-settings: 'wdth' 700, 'wght' 300;
  }
  *, *::before, *::after {
    box-sizing: border-box;
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: ${({theme}) => theme.fontFamily.domaine};
  }
`;



const Layout = ( props ) => {
  const { hiddenHeader, children } = props
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalFonts/>
        <GlobalStyle/>
        <Fader />
        {hiddenHeader ? (<Header displayNone />) : (<Header />)}
        <main className={`${hiddenHeader ? "" : "margin-header"}`}>{children}</main>
        <Footer/>
      </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
