import {colors} from './colors';
import {fontFamily} from './fontFamily';

export const theme = {
  colors,
  fontFamily,
  font: {
    thin: 300,
    regular: 400,
    bold: 700,
  },
  media: {
    mobile: '@media (max-width: 768px)',
    tablet: '@media (max-width: 1024px)',
  }
}