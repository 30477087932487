import React, { Component } from "react";
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Menu from "./Menu"
import HeaderLogo from "./HeaderLogo"

export default class Header extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.toggleBodyClass);
    this.toggleBodyClass();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.toggleBodyClass);
  }

  toggleBodyClass = () => {
    if(this.props.displayNone) {
      if (window.scrollY > window.innerHeight) {
        document.getElementById("header").classList.add("scroll");
      } else {
        document.getElementById("header").classList.remove("scroll");
      }
    } else {
      if (window.scrollY > 0) {
        document.getElementById("header").classList.add("scroll");
      } else {
        document.getElementById("header").classList.remove("scroll");
      }
    }
  };

  render() {

    return (
      <header id="header" className={`${this.props.displayNone ? 'none' : ''} header container-fluid px-4 px-md-5 pt-3 pb-4 mb-2`}>
        <div className="row px-0 justify-content-between align-items-center">
          <div className="col-4">
            <Link to={'/'}>
              <HeaderLogo/>
            </Link>
          </div>
          <div className="col-8 d-flex justify-content-end">
            <Menu/>

          </div>
        </div>

      </header>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

